import { IDENTITY_CONFIG, METADATA_OIDC } from "constants/Auth";
import { UserManager, Log, WebStorageStateStore } from "oidc-client";
import { StorageAuthStateStore } from "app/storageAuthStateStore";
import { Cookies } from "react-cookie";
import { setRequestHeader } from "utils/auth";
import store from "app/store";
import { logoutUser, storeUser } from "stores/authSlice";
import axiosInstance from "app/interceptors";
import { addOIDCUser, removeOIDCUser } from "stores/oidcSlice";

const cookies = new Cookies();

export default class AuthService {
  UserManager;

  constructor() {
    this.UserManager = new UserManager({
      ...IDENTITY_CONFIG,
      userStore: new StorageAuthStateStore(),
      // stateStore: new WebStorageStateStore(),
      metadata: {
        ...METADATA_OIDC,
      },
    });

    // Logger
    // Log.logger = console;
    // Log.level = Log.DEBUG;

    this.UserManager.events.addUserLoaded((user) => {
      store.dispatch(addOIDCUser(user));
      setRequestHeader(user?.access_token);
      axiosInstance
        .get(`/user-management/v1/User/internal/${user?.profile?.UserId}`, {
          headers: {
            Authorization: `Bearer ${user?.access_token}`,
          },
        })
        .then((response) => response.data.data)
        .then((data) => {
          store.dispatch(
            storeUser({
              ...user?.profile,
              userAccess: data?.userAccess.find(
                (val) => val.moduleName === "Management Risiko"
              ),
            })
          );
          if (window.location.href.indexOf("signin-oidc") !== -1) {
            window.location.replace(localStorage.getItem("redirectUri"));
          }
        });
    });

    this.UserManager.events.addUserUnloaded(() => {
      console.log("user unloaded");
      localStorage.clear();
      sessionStorage.clear();
      store.dispatch(logoutUser());
      store.dispatch(removeOIDCUser());
    });

    this.UserManager.events.addSilentRenewError((e) => {
      console.log("silent renew error", e.message);
    });

    this.UserManager.events.addAccessTokenExpired(() => {
      console.log("token expired");
      this.logout();
    });
  }

  signinRedirectCallback = async () => {
    try {
      const user = await this.UserManager.signinRedirectCallback();
      store.dispatch(storeUser(user?.profile));
      store.dispatch(addOIDCUser(user));
      setRequestHeader(user?.access_token);
    } catch (error) {
      console.log('error: ', error);
      
    }
  };

  signinRedirect = async () => {
    // store.dispatch(userSignedOut());
    localStorage.setItem("redirectUri", window.location.pathname);
    await this.UserManager.signinRedirect({});
  };

  isAuthenticated = async () => {
    const user = await this.UserManager.getUser();
    // const user = store.getState().auth.user;
    // const idsrv = cookies.get("idsrv");
    // const idsrvSession = cookies.get("idsrv.session");

    return !!user;
  };

  signinSilent = () => {
    this.UserManager.signinSilent()
      .then((user) => {
        console.log("signed in", user);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  signinSilentCallback = () => {
    this.UserManager.signinSilentCallback();
  };

  logout = async () => {
    try {
      await this.UserManager.signoutRedirect();
      await this.UserManager.clearStaleState();
      store.dispatch(logoutUser());
    } catch (error) {
      console.log('error: ', error);
    }
    // this.UserManager.signoutRedirect({
    //     id_token_hint: localStorage.getItem("id_token")
    // });
    // localStorage.clear();

    // this.UserManager.clearStaleState();
    // this.UserManager.removeUser();
    // store.dispatch(logoutUser());

    // this.UserManager.signoutRedirect();
  };

  signoutRedirectCallback = async () => {
    await this.UserManager.signoutRedirectCallback();
    localStorage.clear();
    sessionStorage.clear();
    store.dispatch(logoutUser());
    store.dispatch(removeOIDCUser());
    window.location.replace("/");
  };
}
